import { Container } from '@mui/material';

export const Wrapper = ({ children }) => {
  return (
    <Container
      disableGutters
      maxWidth="xl"
      sx={{ paddingX: { xxs: 4.5, md: 12.5, xl: 21.25 } }}
    >
      {children}
    </Container>
  );
};
