import { createContext, useContext, useState, useEffect } from 'react';
import { useGetUserById } from 'services/useGetUserById';

const UserContext = createContext();

export const useCurrentUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem('id') ?? '');
  const [userData, setUserData] = useState(null);

  const { data, loading, error, refetch } = useGetUserById(user);

  useEffect(() => {
    if (loading) return;
    if (!data) {
      setUserData(null);
      return;
    }
    setUserData(data.user);
  }, [data, error, loading]);

  return (
    <UserContext.Provider
      value={{ user, setUser, userData, setUserData, refetch }}
    >
      {children}
    </UserContext.Provider>
  );
};
