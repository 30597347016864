import { createTheme } from '@mui/material/styles';
import { ReactComponent as Checked } from 'images/checked.svg';
import { ReactComponent as Unhecked } from 'images/unchecked.svg';
import { ReactComponent as Arrow } from 'images/arrow.svg';

export const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: 390,
      sm: 780,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiCheckbox: {
      defaultProps: {
        icon: <Unhecked />,
        checkedIcon: <Checked />,
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          right: 16,
          top: 'auto',
        },
      },
      defaultProps: {
        IconComponent: Arrow,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          transition: 'all 500ms cubic-bezier(0.4, 0, 0.2, 1)',
          '& .MuiOutlinedInput-root.Mui-error': {
            bgcolor: 'white',
            '& fieldset': {
              border: '1px solid red',
            },
            '&:hover fieldset': {
              border: '1px solid red',
            },
            '&.Mui-focused fieldset': {
              border: '1px solid red',
            },
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
            '&:hover fieldset': {
              border: '1px solid #4793f2',
            },
            '&.Mui-focused fieldset': {
              border: '1px solid #4793f2',
            },
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        },
      },
    },
  },
  palette: {
    primary: {
      light: '#fff',
      main: '#101010',
      dark: '#101010',
      contrastText: '#485058',
    },
    secondary: {
      light: '#2293F9',
      main: '#2293F9',
      dark: '#2293F9',
      contrastText: '#212121',
    },
    pagination: {
      light: '#fff',
      main: '#ff9d00',
      dark: '#9a9a9a',
      contrastText: '#fff',
    },
    custom: {
      headerBg: '#2293F9',
      darkBg: '#f7f7f7',
      accent: '#ff9d00',
      additional: '#9a9a9a',
      darkTxt: '#101010',
      input: '#4793f2',
      black: '#000',
      borders: '#B9B9B9',
      lightBg: '#e9f4ff',
      bg: '#246EB1',
    },
  },
  typography: {
    body1: {
      fontFamily: 'Arial',
      fontSize: '16px',
      color: '#fff',
    },
    body2: {
      fontFamily: 'Arial',
      lineHeight: 1.125,
      color: '#9a9a9a',
    },
    body3: {
      fontFamily: 'Arial',
      fontSize: '18px',
      lineHeight: 1.67,
      color: '#485058',
    },
    body4: {
      fontFamily: 'Arial',
      fontSize: '18px',
      lineHeight: 1.67,
      color: '#9a9a9a',
    },
    subtitle1: {
      fontFamily: 'Arial',
      fontWeight: 700,
      lineHeight: 1.16,
      color: '#2293f9',
    },
    subtitle2: {
      fontFamily: 'Arial',
      lineHeight: 1.125,
      color: '#9a9a9a',
    },
    subtitle3: {
      lineHeight: 1.48,
      color: '#101010',
      fontFamily: 'Poppins',
      fontWeight: 600,
    },
    subtitle4: {
      lineHeight: 1.5,
      color: '#2293F9',
      fontFamily: 'Poppins',
      fontWeight: 600,
    },
    subtitle5: {
      fontFamily: 'Arial',
      fontSize: '21px',
      lineHeight: 1.14,
      color: '#9a9a9a',
    },
    subtitle6: {
      fontSize: '18px',
      lineHeight: 1.16,
      color: '#2293F9',
    },
    description: {
      fontFamily: 'Arial',
      fontSize: '11px',
      lineHeight: 1.09,
      color: '#9a9a9a',
    },
    footerContacts: {
      fontFamily: 'Arial',
      fontSize: '16px',
      color: '#fff',
      lineHeight: 1.75,
    },
    footerLinks: {
      fontFamily: 'Arial',
      fontSize: '16px',
      color: '#fff',
      lineHeight: 2.375,
      transition: 'all 500ms cubic-bezier(0.4, 0, 0.2, 1)',
      '&:hover': {
        color: '#ff9d00',
        cursor: 'pointer',
      },
      '&:focus': {
        color: '#ff9d00',
        cursor: 'pointer',
      },
    },
    footerTitle: {
      fontFamily: 'Arial',
      fontSize: '21px',
      color: '#fff',
      lineHeight: 2.375,
      fontWeight: 700,
    },
    h1: {
      fontFamily: 'Poppins',
      color: '#fff',
      lineHeight: 1.48,
      fontWeight: 600,
    },
    h2: {
      fontFamily: 'Poppins',
      fontSize: '34px',
      color: '#101010',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    herotxt: {
      fontFamily: 'Arial',
      color: '#fff',
      lineHeight: 1.67,
    },
    black: {
      color: '#101010',
    },
    blackSmall: {
      lineHeight: 1.16,
      color: '#101010',
    },
    blueSmall: {
      lineHeight: 1.16,
      fontWeight: 700,
      color: '#2293F9',
    },
    price: {
      fontSize: '34px',
      lineHeight: 1.15,
      color: '#2293f9',
    },
    pagination: {
      fontFamily: 'Arial',
      fontSize: '16px',
      lineHeight: 1.125,
      color: '#2293f9',
      fontWeight: 700,
    },
  },
});
