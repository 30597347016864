import { Stack, Button, Typography, IconButton } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Close } from '../../images/close.svg';

export const MobileMenu = ({ navLinks, onCloseMenuClick }) => {
  const onBackDropClick = e => {
    if (e.currentTarget === e.target) {
      onCloseMenuClick();
    }
  };
  return (
    <Stack
      onClick={onBackDropClick}
      sx={{
        bgcolor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 102,
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        right: 0,
        overflowY: 'scroll',
      }}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bgcolor: 'custom.headerBg',
          pt: 6,
          height: '100vh',
          width: '40vw',
          minWidth: 200,
          zIndex: 103,
        }}
        spacing={2}
      >
        <IconButton
          sx={{ position: 'absolute', top: 16, right: 16 }}
          onClick={onCloseMenuClick}
        >
          <Close style={{ stroke: '#fff' }} />
        </IconButton>
        <Stack>
          {navLinks.map(({ title, path }) => (
            <Stack
              key={title + 'mobile-menu-link'}
              sx={{
                borderTop: '1px solid rgba(255, 255, 255, 0.2)',
                '&:last-of-type': {
                  borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
                },
                '&:has(> a.active)': {
                  backgroundColor: 'rgba(255,255,255,0.2)',
                },
              }}
            >
              <Button
                component={NavLink}
                to={path}
                onClick={onCloseMenuClick}
                sx={{
                  textTransform: 'none',
                  py: 2,
                  px: 4,
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
              >
                <Typography fontSize={{ xxs: 14, sm: 16 }}>{title}</Typography>
              </Button>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
