import { useQuery, gql } from '@apollo/client';

const GET_USER = gql`
  query GetUser($id: ID!) {
    user(id: $id, idType: ID) {
      id
      firstName
      lastName
      email
      userData {
        phone
        isBusiness
        companyName
      }
    }
  }
`;

export const useGetUserById = id => {
  const { data, loading, error, refetch } = useQuery(GET_USER, {
    variables: { id },
  });
  return { data, loading, error, refetch };
};
