import { CircularProgress, Box } from '@mui/material';

export const Loader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '55vh',
      }}
    >
      <CircularProgress
        sx={{
          color: 'custom.headerBg',
        }}
      />
    </Box>
  );
};
