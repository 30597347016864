import { Navigate } from 'react-router-dom';
import React from 'react';
import { useCurrentUser } from 'hooks/useCurrentUser';

const PublicRoute = ({ children, redirectPath = '/', restricted = false }) => {
  const { user } = useCurrentUser();
  const shouldRedirect = user && restricted;

  if (shouldRedirect) {
    return <Navigate to={redirectPath} />;
  }
  return children;
};

export default PublicRoute;
