import { Stack, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { scrollToTop } from 'utiles/scrollToTop';

export const Logo = ({ isHeader }) => {
  return (
    <Stack
      as={Link}
      to="/"
      flexShrink={0}
      direction="row"
      alignItems="center"
      onClick={scrollToTop}
    >
      <Box
        sx={{
          width: isHeader ? { xxs: 27, sm: 35, lg: 43 } : 43,
          height: isHeader ? { xxs: 26, sm: 34, lg: 41 } : 41,
          bgcolor: 'custom.bg',
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mr: 0.5,
        }}
      >
        <Box
          sx={{
            boxSizing: 'border-box',
            width: isHeader ? { xxs: 25, sm: 33, lg: 40 } : 40,
            height: isHeader ? { xxs: 23, sm: 31, lg: 38 } : 38,
            bgcolor: 'custom.headerBg',
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px solid #FFFFFF',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: isHeader ? { xxs: 20, sm: 30, lg: 40 } : 40,
              fontWeight: 700,
            }}
          >
            P
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{
          color: '#646464',
          fontFamily: 'Poppins',
          fontSize: isHeader ? { xxs: 17, sm: 20, lg: 23 } : 23,
          fontWeight: 700,
        }}
      >
        arkeerspecialist
        <Typography
          as="span"
          sx={{
            fontWeight: 700,
            fontFamily: 'Poppins',
            color: 'custom.accent',
            fontSize: isHeader ? { xxs: 9, sm: 12, lg: 15 } : 15,
          }}
        >
          .nl
        </Typography>
      </Typography>
    </Stack>
  );
};
