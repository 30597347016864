import { Outlet } from 'react-router-dom';
import { Suspense, useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Stack,
  Box,
  Typography,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import { addLinks } from './data';
import { Logo } from 'components/Logo/Logo';
import { ReactComponent as Check } from '../../images/check.svg';
import { ReactComponent as UserLogo } from '../../images/user.svg';
import { NavButton } from 'components/NavButton/NavButton';
import { Wrapper } from 'components/Wrapper/Wrapper';
import { device } from 'utiles/devices';
import { ReactComponent as Menu } from 'images/menu.svg';
import { MobileMenu } from 'components/MobileMenu/MobileMenu';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Loader } from 'components/Loader/Loader';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useGetAllMenus } from 'services/useGetAllMenus';
import { useContactQuery } from 'services/useGetContact';
import { flatListToHierarchical } from 'utiles/flatListToHierarchical';
import { scrollToTop } from 'utiles/scrollToTop';

export const SharedLayout = () => {
  const [isShowNavigation, setIsShowNavigation] = useState(true);
  const isMobile = useMediaQuery(device.beforeSM);
  const { user, userData } = useCurrentUser();
  const { navLinks, benefits, footerLinks } = useGetAllMenus();
  const { data: contactData } = useContactQuery();

  const bodyTarget = useRef(document.querySelector('body'));

  const handleKeyDown = e => {
    if (e.code === 'Escape') {
      setIsShowNavigation(false);
    }
  };

  useEffect(() => {
    if (!isMobile) {
      enableBodyScroll(bodyTarget);
      return;
    }
    if (isShowNavigation) {
      window.addEventListener('keydown', handleKeyDown);
      disableBodyScroll(bodyTarget);
      return;
    }

    window.removeEventListener('keydown', handleKeyDown);
    enableBodyScroll(bodyTarget);
  }, [isShowNavigation, isMobile]);

  useEffect(() => {
    if (isMobile) {
      setIsShowNavigation(false);
      return;
    }
    setIsShowNavigation(true);
  }, [isMobile]);

  const onMenuBtnClickHandler = () => {
    setIsShowNavigation(true);
  };

  const onCloseMenuClick = () => {
    setIsShowNavigation(false);
  };

  return (
    <div
      style={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <header>
        <Stack
          direction="row"
          spacing={{ sm: 5, lg: 12 }}
          justifyContent="center"
          bgcolor="custom.darkBg"
          p={2}
          display={{ xxs: 'none', sm: 'flex' }}
        >
          {benefits.map(({ key, title }) => (
            <Stack key={key} direction="row" alignItems="center" spacing={1}>
              <Box
                sx={{
                  backgroundColor: 'custom.accent',
                  borderRadius: '50%',
                  width: 16,
                  height: 16,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Check />
              </Box>
              <Typography variant="body2">{title}</Typography>
            </Stack>
          ))}
        </Stack>
        <Wrapper>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height={{ xxs: 48, sm: 60, lg: 78 }}
          >
            <Logo isHeader />
            <Stack
              as={NavLink}
              to={user ? '/account/overzicht' : '/login'}
              direction="row"
              spacing={0.5}
            >
              <UserLogo
                style={
                  isMobile
                    ? { width: 27, height: 27 }
                    : { width: 31, height: 31 }
                }
              />
              <Stack display={{ xxs: 'none', sm: 'flex' }}>
                {userData?.firstName ? (
                  <>
                    <Typography variant="subtitle1" sx={{ my: 'auto' }}>
                      Hallo, {userData.firstName}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle1">Mijn account</Typography>
                    <Typography variant="description">
                      Inloggen of registreren
                    </Typography>
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Wrapper>
        <Box sx={{ bgcolor: 'custom.headerBg', height: { xxs: 48, sm: 58 } }}>
          <Wrapper>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row">
                {user &&
                  addLinks.map(({ name, path, icon }, idx) => (
                    <NavButton
                      name={name}
                      path={path}
                      accent={idx === 0 ? true : false}
                      add
                      icon={icon}
                      key={name + 'header-add'}
                    />
                  ))}
              </Stack>
              {isMobile && (
                <IconButton onClick={onMenuBtnClickHandler}>
                  <Menu />
                </IconButton>
              )}
              {isShowNavigation && (
                <nav>
                  {isMobile ? (
                    <MobileMenu
                      navLinks={navLinks}
                      onCloseMenuClick={onCloseMenuClick}
                    />
                  ) : (
                    <Stack direction="row">
                      {navLinks.map(({ title, path }) => (
                        <NavButton
                          name={title}
                          path={path}
                          key={title + 'header-link'}
                        />
                      ))}
                    </Stack>
                  )}
                </nav>
              )}
            </Stack>
          </Wrapper>
        </Box>
      </header>
      <main style={{ flex: '1 1 auto' }}>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </main>
      <Box as="footer" bgcolor="custom.headerBg">
        <Wrapper>
          <Stack>
            <Stack
              py="60px"
              direction={{ xxs: 'column', md: 'row' }}
              flexWrap={{ xxs: 'wrap', sm: 'nowrap' }}
            >
              <Stack
                spacing={2}
                mr={{ xxs: 0, sm: '2vw', md: '6vw', lg: '10vw', xl: 27.5 }}
              >
                <Logo />
                {contactData && (
                  <Stack spacing={4}>
                    <Typography variant="footerContacts">
                      {contactData.optionsGroupSettings.address}
                      <br />
                      {contactData.optionsGroupSettings.postcode}{' '}
                      {contactData.optionsGroupSettings.city}
                    </Typography>
                    <Typography variant="footerContacts">
                      <Typography
                        variant="footerLinks"
                        as="a"
                        href={
                          'mailto:' + contactData.optionsGroupSettings.email
                        }
                      >
                        {contactData.optionsGroupSettings.email}
                      </Typography>{' '}
                      <br />
                      <Typography
                        variant="footerLinks"
                        as="a"
                        href={
                          'tel:' +
                          contactData.optionsGroupSettings.phone.replace(
                            /[^0-9]/g,
                            ''
                          )
                        }
                      >
                        {contactData.optionsGroupSettings.phone}
                      </Typography>
                    </Typography>
                  </Stack>
                )}
              </Stack>
              <Stack
                direction={{ xxs: 'column', sm: 'row' }}
                width="100%"
                justifyContent="space-between"
                flexWrap="wrap"
                spacing={{ xxs: 7.5, sm: 0 }}
                mt={{ xxs: 7.5, md: 2.5 }}
              >
                {flatListToHierarchical(footerLinks).map(
                  ({ key, title, children }) => (
                    <Stack key={key}>
                      <Typography variant="footerTitle">{title}</Typography>
                      {children.map(({ key, title, path }) => (
                        <Typography
                          component={NavLink}
                          to={path}
                          key={key}
                          onClick={scrollToTop}
                          reloadDocument={path.includes('?')}
                          variant="footerLinks"
                        >
                          {title}
                        </Typography>
                      ))}
                    </Stack>
                  )
                )}
                {}
              </Stack>
            </Stack>
            <Stack
              pt="30px"
              pb={{ xxs: '9px', sm: '32px' }}
              direction={{ xxs: 'column', sm: 'row' }}
              gap={{ xxs: '30px', sm: 0 }}
              justifyContent="space-between"
              borderTop={1}
              borderColor="primary.light"
            >
              <Typography>© 2024 Parkeerspecialist.nl</Typography>
              <Typography>
                Website:{' '}
                <Typography
                  variant="footerLinks"
                  as="a"
                  href="https://webba.nl/"
                  target="_blank"
                  rel="noreferrer"
                  fontWeight={700}
                >
                  Webba
                </Typography>
              </Typography>
            </Stack>
          </Stack>
        </Wrapper>
      </Box>
    </div>
  );
};
