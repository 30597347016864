import { NavLink } from 'react-router-dom';
import { StyledButton, AccentButton } from './NavButton.styled';
import { Typography, Stack, Box } from '@mui/material';

export const NavButton = ({ path, name, accent, add, icon }) => {
  return (
    <>
      {add ? (
        accent ? (
          <AccentButton
            as={NavLink}
            to={path}
            height={{ xxs: '48px', sm: '58px' }}
          >
            <Stack direction="row" spacing={0.875} alignItems={'center'}>
              <Box
                sx={{
                  width: { xxs: 14, sm: 21 },
                  height: { xxs: 14, sm: 21 },
                  bgcolor: 'primary.light',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  color="custom.accent"
                  sx={{
                    fontSize: { xxs: 8, sm: 12, lg: 16 },
                    fontWeight: 700,
                  }}
                >
                  {icon}
                </Typography>
              </Box>
              <Typography fontSize={{ xxs: 13, sm: 14, md: 16 }}>
                {name}
              </Typography>
            </Stack>
          </AccentButton>
        ) : (
          <StyledButton as={NavLink} to={path}>
            <Stack direction="row" spacing={0.875} alignItems={'center'}>
              <Box
                sx={{
                  width: { xxs: 14, sm: 21 },
                  height: { xxs: 14, sm: 21 },
                  bgcolor: 'primary.light',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  color="custom.headerBg"
                  as="span"
                  sx={{
                    fontSize: { xxs: 8, sm: 12, lg: 16 },
                    fontWeight: 700,
                    transition: 'all 500ms cubic-bezier(0.4, 0, 0.2, 1)',
                  }}
                >
                  {icon}
                </Typography>
              </Box>
              <Typography fontSize={{ xxs: 13, sm: 14, lg: 18 }}>
                {name}
              </Typography>
            </Stack>
          </StyledButton>
        )
      ) : (
        <StyledButton as={NavLink} to={path}>
          <Typography fontSize={{ xxs: 13, sm: 14, md: 16 }}>{name}</Typography>
        </StyledButton>
      )}
    </>
  );
};
