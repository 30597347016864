import { useQuery, gql } from '@apollo/client';

const GET_MENUS_QUERY = gql`
  query GetMenus {
    menus {
      nodes {
        id: databaseId
        name
        menuItems(first: 20) {
          nodes {
            key: id
            parentId
            title: label
            path
          }
        }
      }
    }
  }
`;

export const useGetAllMenus = () => {
  const { data, error, loading } = useQuery(GET_MENUS_QUERY);
  const navLinks =
    data?.menus.nodes.find(({ name }) => name === 'Main menu').menuItems
      .nodes ?? [];
  const benefits =
    data?.menus.nodes.find(({ name }) => name === 'USP Menu').menuItems.nodes ??
    [];
  const footerLinks =
    data?.menus.nodes.find(({ name }) => name === 'Footer Menu').menuItems
      .nodes ?? [];

  return { navLinks, benefits, footerLinks, loading, error };
};
