import { GlobalStyle } from './components/GlobalStyle';
import { Routes, Route } from 'react-router-dom';
import { lazy } from 'react';
import { ToastContainer } from 'react-toastify';
import { SharedLayout } from './components/SharedLayout/SharedLayout';
import PrivateRoute from 'components/PrivateRoute';
import PublicRoute from 'components/PublicRoute';
import { useCurrentUser } from 'hooks/useCurrentUser';

const Home = lazy(() => import('./pages/Home'));
const Contact = lazy(() => import('./pages/Contact'));
const Aanbod = lazy(() => import('./pages/Aanbod'));
const OverOns = lazy(() => import('./pages/OverOns'));
const Partners = lazy(() => import('./pages/Partners'));
const Plaatsen = lazy(() => import('./pages/Plaatsen'));
const Tekstpagina = lazy(() => import('./pages/Tekstpagina'));
const Voorwaarden = lazy(() => import('./pages/Voorwaarden'));
const Vraag = lazy(() => import('./pages/Vraag'));
const Product = lazy(() => import('./pages/Product'));
const Login = lazy(() => import('./pages/Login'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const Account = lazy(() => import('./pages/Account'));
const Overzicht = lazy(() => import('./pages/Overzicht'));
const MijnAdvertenties = lazy(() => import('./pages/MijnAdvertenties'));
const Gegevens = lazy(() => import('./pages/Gegevens'));
const MijnVragen = lazy(() => import('./pages/MijnVragen'));
const Registreren = lazy(() => import('./pages/Registration'));
const NotFound = lazy(() => import('./pages/NotFound'));
const EditProduct = lazy(() => import('./pages/EditProduct'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

export const App = () => {
  const { userData } = useCurrentUser();
  return (
    <>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<SharedLayout />}>
          <Route index element={<Home />} />
          <Route path="contact" element={<Contact />} />
          <Route path="aanbod" element={<Aanbod />} />
          <Route path="aanbod/:productId" element={<Product />} />
          <Route
            path="aanbod/:productId/edit"
            element={
              <PrivateRoute>
                <EditProduct />
              </PrivateRoute>
            }
          />
          <Route path="over-ons" element={<OverOns />} />
          <Route path="partners" element={<Partners />} />
          <Route
            path="plaatsen"
            element={
              <PrivateRoute>
                <Plaatsen />
              </PrivateRoute>
            }
          />
          <Route path="tekstpagina" element={<Tekstpagina />} />
          <Route path="voorwaarden" element={<Voorwaarden />} />
          <Route
            path="vraag"
            element={
              <PrivateRoute>
                <Vraag />
              </PrivateRoute>
            }
          />
          <Route
            path="login"
            element={
              <PublicRoute redirectPath="/account/mijnadvertenties">
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="forgot-password"
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />
          <Route
            path="reset-password/:key"
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
          <Route
            path="account"
            element={
              <PrivateRoute redirectPath="/login">
                <Account />
              </PrivateRoute>
            }
          >
            <Route path="overzicht" element={<Overzicht />} />
            <Route path="mijnadvertenties" element={<MijnAdvertenties />} />
            <Route path="gegevens" element={<Gegevens />} />
            <Route path="mijnvragen" element={<MijnVragen />} />
          </Route>
          <Route
            path="registration"
            element={
              <PrivateRoute
                forceRedirect={userData && userData.userData.phone}
                forcePath="/account/overzicht"
                redirectPath="/login"
              >
                <Registreren />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        newestOnTop
        theme="colored"
      />
    </>
  );
};
