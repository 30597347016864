import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from 'App';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import { BrowserRouter } from 'react-router-dom';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  Observable,
} from '@apollo/client';
import { GRAPH_API_URL } from 'env';
import { onError } from '@apollo/client/link/error';
import { REFRESH_ACCESS_MUTATION } from 'services/useRefreshAccessTokenMutation';
import { UserProvider } from 'hooks/useCurrentUser';
import { GraphQLError } from 'graphql';
import { HelmetProvider } from 'react-helmet-async';
import { removeUserData } from 'utiles/removeUserData';
import { createUploadLink } from 'apollo-upload-client';

const authLink = new ApolloLink((operation, forward) => {
  const accessToken = localStorage.getItem('accessToken');
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  }));

  return forward(operation);
});

const refreshToken = async () => {
  try {
    localStorage.removeItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const refreshResolverResponse = await client.mutate({
      mutation: REFRESH_ACCESS_MUTATION,
      variables: { refreshToken },
    });
    const accessToken =
      refreshResolverResponse.data?.refreshJwtAuthToken.authToken;
    localStorage.setItem('accessToken', accessToken || '');
    return accessToken;
  } catch (err) {
    removeUserData();
    window.location.reload();
    throw err;
  }
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        if (err.message === 'Expired token') {
          if (operation.operationName === 'RefreshAuthToken') return;

          const observable = new Observable(observer => {
            (async () => {
              try {
                const accessToken = await refreshToken();
                if (!accessToken) {
                  throw new GraphQLError('Empty AccessToken');
                }
                // Retry the failed request
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                forward(operation).subscribe(subscriber);
              } catch (err) {
                observer.error(err);
              }
            })();
          });

          return observable;
        }
      }
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);

const uploadLink = new createUploadLink({
  uri: GRAPH_API_URL,
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, uploadLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Object_Objectdata: {
        keyFields: ['address'],
        merge(existing, incoming) {
          return {
            ...existing,
            ...incoming,
          };
        },
      },
    },
  }),
});

const helmetContext = {};

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <UserProvider>
            <HelmetProvider context={helmetContext}>
              <App />
            </HelmetProvider>
          </UserProvider>
        </ThemeProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);
