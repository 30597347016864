import { useMutation, gql } from '@apollo/client';
import { extractErrorMessage } from 'utiles/extractErrorMessage';

export const REFRESH_ACCESS_MUTATION = gql`
  mutation RefreshAuthToken($refreshToken: String!) {
    refreshJwtAuthToken(
      input: { clientMutationId: "uniqueId", jwtRefreshToken: $refreshToken }
    ) {
      authToken
    }
  }
`;

export const useRefreshAccessTokenMutation = () => {
  const [refreshJwtAuthToken, { data, loading, error }] = useMutation(
    REFRESH_ACCESS_MUTATION
  );

  const mutate = async refreshToken => {
    try {
      const response = await refreshJwtAuthToken({
        variables: { refreshToken },
      });
      return response.refreshJwtAuthToken.authToken;
    } catch (err) {
      throw new Error(extractErrorMessage(err.message));
    }
  };

  return { mutate, data, loading, error };
};
