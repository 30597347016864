import styled from 'styled-components';
import { Button } from '@mui/material';
import { device } from 'utiles/devices';

export const StyledButton = styled(Button)`
  height: 48px;
  border-radius: 0;
  display: flex;
  align-items: center;
  padding: 0 10px;
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
  :hover,
  :focus,
  :active,
  &.active {
    background-color: #ff9d00;
  }

  &:hover span,
  &:focus span,
  &.active span {
    color: #ff9d00;
  }

  @media ${device.sm} {
    padding: 17px 16px;
    height: 58px;
  }

  @media ${device.lg} {
    padding: 17px 34px;
  }
`;

export const AccentButton = styled(Button)`
  border-radius: 0;
  height: 48px;
  background-color: #ff9d00;
  display: flex;
  align-items: center;
  padding: 0 10px;
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
  :hover,
  :focus,
  :active {
    background-color: #ff9d00;
  }

  @media ${device.sm} {
    padding: 0 16px;
    height: 58px;
  }

  @media ${device.lg} {
    padding: 0 34px;
  }
`;
