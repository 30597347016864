import { createGlobalStyle } from 'styled-components';
import 'modern-normalize';
import 'swiper/css';
import 'react-toastify/dist/ReactToastify.css';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

export const GlobalStyle = createGlobalStyle`
  html {
  box-sizing: border-box;
  width: 100vw;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100%;
  
  margin: 0;
  font-family: 'Arial', sans-serif;
  color: #212121;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0 !important;
}



img {
  display: block;
  max-width: 100%;
  height: auto;
}
  


ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #101010;
  line-height: 1.5;
  font-family: "Poppins";
  font-weight: 600;
  margin-bottom: 10px;
}

h1 {
    font-size: 24px;
  @media screen and (min-width: 780px ) {
  font-size: 28px;
  };
    @media screen and (min-width: 1024px ) {
  font-size: 32px;
  };
    @media screen and (min-width: 1920px ) {
  font-size: 34px;
  };
}

h2 {
    font-size: 21px;
  @media screen and (min-width: 780px ) {
  font-size: 24px;
  };
    @media screen and (min-width: 1024px ) {
  font-size: 26px;
  };
    @media screen and (min-width: 1920px ) {
  font-size: 28px;
  };
}

h3, h4, h5, h6 {
    font-size: 13px;
  @media screen and (min-width: 780px ) {
  font-size: 14px;
  };
    @media screen and (min-width: 1024px ) {
  font-size: 16px;
  };
    @media screen and (min-width: 1920px ) {
  font-size: 18px;
  };
}


p {
  margin: 0;
  color: #485058;
  line-height: 1.61;
  font-size: 13px;
  @media screen and (min-width: 780px ) {
  font-size: 15px;
  };
    @media screen and (min-width: 1024px ) {
  font-size: 16px;
  };
    @media screen and (min-width: 1280px ) {
  font-size: 17px;
  };
    @media screen and (min-width: 1920px ) {
  font-size: 18px;
  };
  
}

a {
  text-decoration: none;
}

/* reset styles library Lightbox */
.yarl__thumbnails_bottom .yarl__thumbnails_vignette, .yarl__thumbnails_top .yarl__thumbnails_vignette{
  background: transparent;
}
`;
