import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import React from 'react';
import { useCurrentUser } from 'hooks/useCurrentUser';

const PrivateRoute = ({
  children,
  redirectPath = '/',
  forceRedirect,
  forcePath,
}) => {
  const { user } = useCurrentUser();

  if (forceRedirect) {
    return <Navigate to={forcePath} replace />;
  }

  if (!user) {
    toast.info(
      'U heeft geen toegang tot deze pagina. Registreer of log in op uw account.'
    );
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default PrivateRoute;
