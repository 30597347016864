import { useQuery, gql } from '@apollo/client';

const GET_CONTACT_QUERY = gql`
  query getContact {
    optionsGroupSettings {
      address
      city
      phone
      email
      postcode
      lat
      lon
    }
  }
`;

export const useContactQuery = () => {
  const { data, loading, error } = useQuery(GET_CONTACT_QUERY);

  return { data, loading, error };
};
