const size = {
  xxs: '0',
  xs: '390px',
  beforeSm: '779px',
  sm: '780px',
  beforeMd: '1023px',
  md: '1024px',
  beforeLg: '1279px',
  lg: '1280px',
  beforeXl: '1919px',
  xl: '1920px',
};

export const device = {
  // sm: `(min-width: ${size.xs})`,
  // beforeSm: `(max-width: ${size.beforeSm})`,
  beforeSM: `(max-width: ${size.sm})`,
  beforeMd: `(max-width: ${size.beforeMd})`,
  sm: `(min-width: ${size.sm})`,
  smLg: `(min-width:${size.sm}) and (max-width:${size.beforeLg})`,
  beforeLg: `(max-width: ${size.beforeLg})`,
  lg: `(min-width: ${size.lg})`,
  // lgXl: `(min-width:${size.lg}) and (max-width:${size.beforeXl})`,
  // beforeXl: `(max-width: ${size.beforeXl})`,
  xl: `(min-width: ${size.xl})`,
};

// how to use:
// @media ${device.lg} {}
